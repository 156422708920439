import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { IRootState } from 'store/reducers';
import { getArticleBySlug } from 'store/actions/article';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button"
import EditorReadOnly from 'components/Editor/EditorReadOnly.jsx';
import CustomFooter from 'components/CustomFooter/custom-footer.component';
// i18n
import { Translation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => createStyles({
	root: {
		padding: "calc(20vh + 2.5vw) 15px 0px 15px",
		fontFamily: "'Montserrat', sans-serif;",
	},
	container: {
		height: "100%",
		width: "100%",
		paddingTop: 15,
	},
	contentWrapper: {
		justifyContent: "center",
		padding: 15
	},
	title: {
		textAlign: "center",
		padding: 25,
		fontSize: 40,
		fontWeight: "bold",
		color: "rgb(46,67, 151)",
	},
	".codex-editor__redactor": {
		paddingBottom: "30px !important"
	}
}))

interface StateProps {
	article: any;
}

interface DispatchProps {
	getArticleBySlug: (slug: string) => any
}

interface ArticlePageProps {
	children?: any;
}

type Props = StateProps & DispatchProps & ArticlePageProps

const ArticlesPage: React.FC<Props> = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { article } = props;
	const [render, setRender] = useState(false);

	useEffect(() => {
		const slug = history.location.pathname.split("/")[2]
		if (slug) {
			props.getArticleBySlug(slug)
		}
	}, [])

	return (
		<Translation>
			{
				t => <div>
					{
						article.content && <>
							<div className={classes.root}>
								<div>
									{
										history.location.pathname.split("/")[2]?.length > 0
										&& <div className={classes.container}>
											<GridContainer>
												<GridItem className={classes.title}>{article.title}</GridItem>
											</GridContainer>
											{
												props.article.slug && <div>
													{
														(props.article.slug === window.location.pathname.split('/')[2]) && <GridContainer className={classes.contentWrapper}>
															<EditorReadOnly initialState={article.content} />
														</GridContainer>
													}
												</div>
											}
										</div>
									}
								</div>
							</div>
							<CustomFooter />
						</>
					}
				</div>
			}
		</Translation>
	)
}


const mapStateToProps = ({ article }: IRootState) => ({
	article: article.article
})

const mapDispatchToProps = {
	getArticleBySlug
}


export default connect<StateProps, DispatchProps, ArticlePageProps>(mapStateToProps, mapDispatchToProps)(ArticlesPage);