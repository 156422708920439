import React from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CallMade from '@material-ui/icons/CallMade';

import { Row, Column, Item } from '@mui-treasury/components/flex';
import { useSizedIconButtonStyles } from '@mui-treasury/styles/iconButton/sized';
import productImg from "assets/img/af-50-1.png"

const StyledTooltip = withStyles({
  tooltip: {
    marginTop: '0.2rem',
    backgroundColor: 'rgba(0,0,0,0.72)',
    color: '#fff',
  },
})(Tooltip);

const useBasicProfileStyles = makeStyles(({ palette }) => ({
  avatar: {
    borderRadius: 8,
    backgroundColor: '#495869',
  },
  overline: {
    fontSize: 10,
    textTransform: 'uppercase',
    letterSpacing: 1,
    color: '#8D9CAD',
    overflow: "hidden"
  },
  name: {
    fontSize: 14,
    fontWeight: 500,
    color: '#495869',
    overflow: "hidden"
  },
}));


const convertModelName = (model) => {
  switch (model) {
    case "Laerdal":
      return "Laerdal"
    case "Olympus":
      return "Olympus"
    case "Phillips":
      return "Phillips"
    case "Natus":
      return "Natus"
    case "OPTIKON_2000_SPA":
      return "OPTIKON 2000 SPA"
    case "Ergoline_GmbH_Amed_Tec_GmbH_Geratherm_GmbH":
      return "Ergoline GmbH"
    case "Scientific_3B":
      return "3B Scientific"
    case "ZOLL_Medical_Corporation":
      return "ZOLL Medical Corporation"

    default:
      break;
  }
}

interface BasicProfileProps {
  model: string
}

const BasicProfile: React.FC<BasicProfileProps> = (props) => {
  const styles = useBasicProfileStyles();
  return (
    <Row>
      {/* <Item><Avatar className={styles.avatar}>S</Avatar></Item> */}
      <Item position={'middle'} pl={{ sm: 0.5, lg: 0.5 }}>
        <Typography className={styles.overline}>MODEL</Typography>
        <Typography className={styles.name}>{convertModelName(props.model)}</Typography>
      </Item>
    </Row>
  );
};

const useCardHeaderStyles = makeStyles(() => ({
  root: { paddingBottom: 0 },
  title: {
    fontSize: '14px',
    color: '#122740',
    height: "21px"
  },
  subheader: {
    fontSize: '0.875rem',
    color: '#495869',
  },
}));

interface CardHeaderProps {
  header: string;
}

const CardHeader: React.FC<CardHeaderProps> = (props) => {
  const styles = useCardHeaderStyles();
  const iconBtnStyles = useSizedIconButtonStyles({ padding: 8, childSize: 20 });
  return (
    <Row>
      <Item position={'middle'}>
        <Typography className={styles.title}>
          <b>{props.header}</b>
        </Typography>
        <Typography className={styles.subheader}>
          {/* Similar to firebase theme */}
        </Typography>
      </Item>
    </Row>
  );
};

const useStyles = makeStyles(() => createStyles({
  card: {
    border: '2px solid',
    borderColor: '#E7EDF3',
    borderRadius: 16,
    transition: '0.4s',
    '&:hover': {
      borderColor: 'rgb(46,67, 151)',
    },
    padding: 15,
    marginBottom: 15,
  },
}));

interface ContentProps {
  name: string;
  model: string;
  image_url?: string;
  brand: string;
  category: string;
}

interface ProductCardProps {
  content: ContentProps
}

export const ProductCard: React.FC<ProductCardProps> = React.memo(function ShowcaseCard(props) {
  const styles = useStyles();
  const { content } = props;

  return (
    <div>
      <div className={styles.card}>
        <CardHeader header={content.model} />
        <Item>
          {/* <Box minHeight={200} bgcolor={'#F4F7FA'} borderRadius={8} /> */}
          <img src={productImg} style={{ width: "100%", borderRadius: 8, backgroundColor: "#F4F7FA" }} />
        </Item>
        <BasicProfile model={content.brand} />
      </div>
    </div>
  );
});
export default ProductCard