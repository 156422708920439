export const LOCALES_EN = {
  company: "MSTC Investment Engineering Service Trading",
  companyShort: "MSTC Investment",
  we: "WE",
  sell: "SELL",
  the: "THE",
  safety: "SAFETY",
  homeHeader: "Home",
  ourStoriesHeader: "Our Stories",
  productsHeader: "Products",
  newsHeader: "News",
  contactUsHeader: "Contact Us",
  learnMore: "Learn more",
  whoAreWe: "WHO ARE WE?",
  aboutUsContent: "Founded in 2016, MSTC is one of leading companies in medical equipments and supplies trading in Vietnam. We hope to bring you the most professional and comfortable experience.",
  whatWeDo: "PRODUCTS",
  medicalEquipmentTrading: "Disinfection and sterilization system (Gettinge)",
  medicalConsumableSupplies: "Drug labeling system",
  maintenanceServices: "Medical gas system and operating room furniture",
  consultant: "German shipping system",
  investment: "Medical gas system and ceiling arm",
  trainingCooperation: "",
  ourGoals: "OUR GOALS",
  goal_1: "Be the professional and effective supplier in Medical equipment and services",
  goal_2: "Be the first choice of major Partners/Hospital/ Companies doing business in Vietnam",
  goal_3: "Promote cooperation connections in the field of Medical training",
  goal_4: "Providing innovative and effective solutions consultancy in the management of hospital",
  contactUs: "Contact Us",
  subscribe: "Subscribe to our E-newsletter",
  submit: "Submit",
  location: "Room 606, Indochina Plaza Hanoi,241 Xuan Thuy Street, Cau Giay District, Ha Noi, Viet nam",
  enterEmail: "Enter your email",
  ourPartners: "OUR PARTNERS",
  aboutUs: "About Us",
  newsLanding: "NEWS",
  ourLocation: "OUR LOCATION:",
  phoneNumber: "PHONE NUMBER:",
  bannerAbout: "ABOUT US",
  bannerProduct: "PRODUCTS",
  bannerNews: "NEWS",
  noProduct: "There are no matching products",
  findOutMore: "Find Out More",
  // about us text
  aboutUsHeader: "ABOUT MSTC",
  aboutUsParagraph1: "MSTC TECHNICAL SERVICE INVESTMENT JOINT STOCK COMPANY is known as the leading company in the field of medical equipment distribution in Vietnam; specializes in distributing high quality product lines belonging to the world's leading brands such as Phillips (USA), Natus (USA), 3B scientific (Germany), Olympus (Japan), ... For us, the experience of Customers are always given priority. Therefore, after-sales customer care services such as warranty, maintenance, repair and user training are an essential part of our business philosophy. We put your safety and trust first, trusting doctors and medical staff with high quality technical support services in the shortest time. MSTC team is working hard every day with the desire to bring profound changes in medical examination and treatment activities and health to people in Vietnam.",
  aboutUsParagraph2: "Along with the development of the medical system, MSTC deploys services in a variety of industries: Anesthesia, Obstetrics and Gynecology, Ultrasound, Orthopedic Trauma, Surgery, Infection Control, Infrastructure, etc. .and ready to add many new categories in the future.",
  aboutUsParagraph3: "During the process of establishment and development, MSTC has become a partner with strong potential, reliable and capable of implementing large medical projects, trusted by many Medical Associations and the Ministry of Health, and chosen to be the partner of many training programs, seminars, and conferences every year.",
  aboutUsParagraph4: "Learn more at "
};