import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { IRootState } from 'store/reducers';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button"
import CustomFooter from 'components/CustomFooter/custom-footer.component';
import NewsCard from 'components/Card/NewsCard';
import GoogleMaps from 'components/Maps/GoogleMaps';
import aboutBanner from "assets/img/about-banner.jpg"
// i18n
import { Translation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: "'Montserrat', sans-serif;",
    color: "rgb(46,67, 151)",
    [theme.breakpoints.down('xs')]: {
      padding: "calc(175px + 3.5vw) 0px 0px 0px",
    },
    [theme.breakpoints.up('xs')]: {
      padding: "calc(140px + 3vw)  0px 0px 0px",
    },
  },
  container: {
    height: "100%",
    maxWidth: "80%",
    margin: "auto",
    padding: 15,
    paddingBottom: 30
    // display: "flex",
    // columnGap: 30,
    // [theme.breakpoints.down('sm')]: {
    //   display: "block",
    // },
  },
  banner: {
    width: "100%",
    height: "350px",
    backgroundImage: `url(${aboutBanner})`,
    backgroundRepeat: "no-repeat, repeat;",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 35,
    fontWeight: "bold",
    color: "#ffffff",
    backgroundSize: "100%",
    [theme.breakpoints.down('xs')]: {
      height: "200px",
    },
  },
  aboutUsHeader: {
    textAlign: "center",
    color: "rgb(46,67, 151)",
  },
  aboutUsContent: {
    maxWidth: "1250px",
    margin: "auto",
  },
  link: {
    cursor: "pointer",
    "&:hover": {
      color: "rgb(46,67, 151)",
    },
  },
  paragraph: {
    fontSize: 17,
    lineHeight: "25px",
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      lineHeight: "25px",
    },
  },
}))

interface StateProps {
}

interface DispatchProps {
}

interface AboutUsProps {
  children?: any;
}

type Props = StateProps & DispatchProps & AboutUsProps

const AboutUsPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Translation>
      {
        t => <div>
          <div className={classes.root}>
            <div className={classes.banner}>
              {t('bannerAbout')}
            </div>
            <div className={classes.container}>
              <GridContainer>
                <GridItem>
                  <h2 className={classes.aboutUsHeader}>{t('aboutUsHeader')}</h2>
                </GridItem>
                <GridItem className={classes.aboutUsContent}>
                  <GridItem className={classes.paragraph}>
                    <p>{t('aboutUsParagraph1')}</p>
                  </GridItem>
                  <GridItem className={classes.paragraph}>
                    <p>{t('aboutUsParagraph2')}</p>
                  </GridItem>
                  <GridItem className={classes.paragraph}>
                    <p>{t('aboutUsParagraph3')}</p>
                  </GridItem>

                  <GridItem className={classes.paragraph}>
                    {t('aboutUsParagraph4')}
                    <a className={classes.link} href="https://mstc-vn.com/">https://mstc-vn.com/</a>
                  </GridItem>

                </GridItem>
              </GridContainer>
            </div>
          </div>
          <CustomFooter />
        </div >
      }
    </Translation>
  )
}

// contact.us@mstc.com
// (+84)904 177 211

const mapStateToProps = ({ article }: IRootState) => ({
})

const mapDispatchToProps = {

}


export default connect<StateProps, DispatchProps, AboutUsProps>(mapStateToProps, mapDispatchToProps)(AboutUsPage);