import { call, put, takeLatest, takeEvery, all } from "redux-saga/effects";
import { EMAIL } from "../root/constants";
import { SUCCESS, FAILURE } from "store/utils/response-types.util";
import { subcribeService } from "store/service/email";

function* subscribe(action: any) {
  try {
    const newData = yield call(subcribeService, action.payload);
    yield put({
      type: SUCCESS(EMAIL.subscribe),
      payload: newData,
    });
  } catch (error) {
    yield put({
      type: FAILURE(EMAIL.subscribe),
      payload: error,
    });
  }
}

export function* watchSubscribe() {
  yield takeEvery(EMAIL.subscribe, subscribe);
}

function* EmailWatcher() {
  yield all([watchSubscribe()]);
}

export default EmailWatcher;
