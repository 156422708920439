import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useRef, useState, useEffect } from 'react';
import { IRootState } from 'store/reducers';
import { getArticleBySlug } from 'store/actions/article';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button"
import CustomFooter from 'components/CustomFooter/custom-footer.component';
import { getArticlesByPage } from "store/actions/article"
import NewsCard from 'components/Card/NewsCard';
import newsBanner from "assets/img/banner-news.jpg"
// i18n
import { Translation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: "'Montserrat', sans-serif; !important",
    [theme.breakpoints.down('xs')]: {
      padding: "calc(175px + 3.5vw) 0px 0px 0px",
    },
    [theme.breakpoints.up('xs')]: {
      padding: "calc(140px + 3vw)  0px 0px 0px",
    },
  },
  container: {
    height: "100%",
    maxWidth: "80%",
    margin: "auto",
    padding: 15,
    paddingBottom: 30
  },
  cardContainer: {
    width: "100%",
    margin: "0px !important",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    },
  },
  banner: {
    width: "100%",
    height: "350px",
    backgroundImage: `url(${newsBanner})`,
    backgroundRepeat: "no-repeat, repeat;",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 35,
    fontWeight: "bold",
    color: "#ffffff",
    backgroundSize: "100%",
    [theme.breakpoints.down('xs')]: {
      height: "200px",
    },
  },
}))

interface StateProps {
  articles: Array<any>;
}

interface DispatchProps {
  getArticlesByPage: ({ page, size }) => any;
}

interface GalleryProps {
  children?: any;
}

type Props = StateProps & DispatchProps & GalleryProps

const ArticlesPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    props.getArticlesByPage({ page: 1, size: 10 })
  }, [])


  return (
    <Translation>
      {
        t => <div>
          {
            props.articles.length > 0
              ? <>
                <div className={classes.root}>
                  <div className={classes.banner}>
                    {t('bannerNews')}
                  </div>
                  <div className={classes.container}>
                    <div>
                      <GridContainer className={classes.cardContainer}>
                        {
                          props.articles.length > 0 && props.articles.map((article, i) =>
                            <NewsCard key={i} content={article} />
                          )
                        }
                      </GridContainer>
                    </div>
                  </div>
                </div>
                <CustomFooter />
              </>
              : <div>
                waiting...
              </div>
          }
        </div>
      }
    </Translation>
  )
}


const mapStateToProps = ({ article }: IRootState) => ({
  articles: article.articles
})

const mapDispatchToProps = {
  getArticlesByPage
}


export default connect<StateProps, DispatchProps, GalleryProps>(mapStateToProps, mapDispatchToProps)(ArticlesPage);