import React, { useRef, useState, useEffect } from 'react';
import '../../assets/scss/component-stylings/custom-header.styles.scss';
import LogoTransparent from '../../assets/img/logo-transparent.png'

import i18n from "i18n"
import { withRouter } from 'react-router';

import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase'
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';

// i18n
import { Translation } from 'react-i18next';
// icon
import UnitedKingdom from "assets/img/united-kingdom.png"
import Vietnam from "assets/img/vietnam.png"

gsap.registerPlugin(CustomEase)


const useStyles = makeStyles((theme) => ({
	headerBtn: {
		width: "41px !important",
		height: "41px !important",
		minWidth: "41px !important",
		padding: "0px !important",
		"&:hover": {
			opacity: 0.8,
		},
		"&:focus": {
			background: "rgb(46,67, 151)",
		},
		[theme.breakpoints.down('xs')]: {
			width: "22px !important",
			height: "22px !important",
			minWidth: "22px !important"
		},
	},
	icon: {
		width: "100%",
		height: "auto"
	},
	btnWrapper: {
		display: "flex",
		flexDirection: "row",
		columnGap: "8px"
	},
}))


const CustomHeader = (props) => {
	const matches = useMediaQuery('(max-width:992px)');
	const classes = useStyles();
	const [prevLoc, setNextLoc] = useState('/')

	const curtain1 = useRef()
	const curtain2 = useRef()

	const handleRouteSwitch = (loc) => {

		setNextLoc(loc)

		let curtainTl = gsap.timeline()
		curtainTl.to([curtain1.current], { duration: .9, scaleY: 1, ease: 'power4.inOut' })
			.to([curtain2.current], { duration: .9, scaleY: 1, ease: 'power4.inOut' }, .35)
			.to([curtain1.current], { webkitTransformOrigin: 'bottom', duration: 0 }, 1.25)
			.to([curtain2.current], { webkitTransformOrigin: 'bottom', duration: 0 }, 1.25)
			.to([curtain2.current], { duration: .9, scaleY: 0, ease: 'power4.inOut' }, 1.25)
			.to([curtain1.current], { duration: .9, scaleY: 0, ease: 'power4.inOut' }, 1.6)
			.to([curtain1.current], { webkitTransformOrigin: 'top', duration: 0 })
			.to([curtain2.current], { webkitTransformOrigin: 'top', duration: 0 })
		curtainTl.pause()

		loc !== props.history.location.pathname ?
			(
				curtainTl.play(),
				setTimeout(() => {
					props.history.push(loc)
				}, 700)
			)
			:
			(
				null
			)
	}

	const [open, setOpen] = React.useState(false)
	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setOpen(open)
	};

	return (
		<Translation>
			{
				t => <div className='header'>
					<GridContainer style={{ justifyContent: "space-between", alignItems: "center", width: "100%", paddingRight: 15 }}>
						<div style={{ display: "flex", alignItems: "center" }}>
							<div className='loading-curtain-1' ref={curtain1}></div>
							<div className='loading-curtain-2' ref={curtain2}></div>
							<img className='logo' src={LogoTransparent} alt="logo" onClick={() => { handleRouteSwitch('/') }} />
							{
								matches === true
									? <span className='branding'>{t("companyShort")}</span>
									: <span className='branding'>{t("company")}</span>
							}
						</div>
						<div className={classes.btnWrapper}>
							<img className={classes.headerBtn} onClick={() => i18n.changeLanguage("en")} src={UnitedKingdom} />
							<img className={classes.headerBtn} onClick={() => i18n.changeLanguage("vi")} src={Vietnam} />
						</div>
					</GridContainer>

					<GridContainer style={{ color: "#ffffff", alignItems: "center", width: "100%", background: "rgb(46,67, 151)", padding: 15 }}>
						<div className='header-nav-container'>
							<span className='header-nav' onClick={() => { handleRouteSwitch('/') }}>{t('homeHeader')}</span>
							<span className='header-nav' onClick={() => { handleRouteSwitch('/about') }}>{t('aboutUs')}</span>
							{/* <span className='header-nav' onClick={() => { handleRouteSwitch('/story') }}>{t('ourStoriesHeader')}</span> */}
							<span className='header-nav' onClick={() => { handleRouteSwitch('/products') }}>{t('productsHeader')}</span>
							<span className='header-nav' onClick={() => { handleRouteSwitch('/gallery') }}>{t('newsHeader')}</span>
							<span className='header-nav' onClick={() => { handleRouteSwitch('/contact-us') }}>{t('contactUsHeader')}</span>
						</div>
					</GridContainer>

				</div>
			}
		</Translation>
	)
}

export default withRouter(CustomHeader)