import {
  call,
  put,
  takeEvery,
  takeLatest,
  all,
  fork,
} from "redux-saga/effects";


import EmailWatcher from "./email";
import ArticleWatcher from "./article";
import ProductWatcher from "./product";

export default function* rootSaga() {
  yield all([
    fork(EmailWatcher),
    fork(ArticleWatcher),
    fork(ProductWatcher)
  ]);
}
