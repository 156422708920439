import axios from "axios";

export const filterService = async (payload: any) => {
  const { page, size, category, brand, country } = payload;
  const pageDefault = page || 1;
  const sizeDefault = size || 6;

  // concatenate query string
  let queryString = `?page=${pageDefault}&size=${sizeDefault}`
  if (category) queryString += `&category=${category}`
  if (brand) queryString += `&brand=${brand}`
  if (country) queryString += `&country=${country}`

  // fetch api
  const res = await axios.get(`/api/product/${queryString}`)

  return res;
};
