import { PRODUCT } from "../root/constants";
import { REQUEST, FAILURE, SUCCESS } from "../utils/response-types.util";

const initialState = {
  loading: false,
  errorMessage: null,
  products: [],
  page: 0,
  size: 0,
  total: 0,
};

export type ProductState = Readonly<typeof initialState>;

export default (state: ProductState = initialState, action: any): ProductState => {
  switch (action.type) {
    case REQUEST(PRODUCT.filter):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(PRODUCT.filter):
      return {
        ...state,
        loading: true,
        errorMessage: action.payload,
      };
    case SUCCESS(PRODUCT.filter):
      return {
        ...state,
        products: action.payload.data.data,
        page: action.payload.data.page,
        size: action.payload.data.size,
        total: action.payload.data.total,
      };

    default:
      return state;
  }
};
