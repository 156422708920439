import { EMAIL } from "../root/constants";
import { REQUEST, FAILURE, SUCCESS } from "../utils/response-types.util";

const initialState = {
  email: "",
  loading: false,
  errorMessage: null,
};

export type EmailState = Readonly<typeof initialState>;
 
export default (state: EmailState = initialState, action: any): EmailState => {
  switch (action.type) {
    case REQUEST(EMAIL.subscribe):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(EMAIL.subscribe):
      return {
        ...state,
        loading: true,
        errorMessage: action.payload,
      };
    case SUCCESS(EMAIL.subscribe):
      return {
        ...state,
        email: action.payload,
      };

    default:
      return state;
  }
};
