import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import CustomHeader from "components/Header/custom-header.component";


// pages
import LandingPage from "views/pages/Landing/landing-page.component";
import ArticlesPage from "views/pages/Articles/articles.component.tsx";
import GalleryPage from "views/pages/Gallery/gallery.component.tsx";
import ContactUsPage from "views/pages/Contact/contactUs.component.tsx"
import AboutUsPage from "views/pages/About/about.component.tsx"
import ProductsPage from "views/pages/Products/products.component.tsx"
import ScrollToTop from "ScrollToTop.tsx";


const AppRoutes = () => {
  return (
    <div className="view-routes">
      <CustomHeader />
      <ScrollToTop>
        <Switch>
          <Route exact path="/"><LandingPage /></Route>
          <Route path="/articles"><ArticlesPage /></Route>
          <Route path="/gallery"><GalleryPage /></Route>
          <Route path="/about"><AboutUsPage /></Route>
          <Route path="/contact-us"><ContactUsPage /></Route>
          <Route path="/products"><ProductsPage /></Route>
        </Switch>
      </ScrollToTop>
    </div>
  );
};

export default AppRoutes;
