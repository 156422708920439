import { ARTICLE } from "../root/constants";
import { REQUEST, FAILURE, SUCCESS } from "../utils/response-types.util";

const initialState = {
  loading: false,
  errorMessage: null,
  articles: [],
  article: {}
};

export type ArticleState = Readonly<typeof initialState>;
 
export default (state: ArticleState = initialState, action: any): ArticleState => {
  switch (action.type) {
    case REQUEST(ARTICLE.getLanding):
    case REQUEST(ARTICLE.getArticleBySlug):
    case REQUEST(ARTICLE.getArticlesByPage):
      return {
        ...state,
        loading: true,
      };
    case FAILURE(ARTICLE.getLanding):
    case FAILURE(ARTICLE.getArticleBySlug):
    case FAILURE(ARTICLE.getArticlesByPage):
      return {
        ...state,
        loading: true,
        errorMessage: action.payload,
      };
    case SUCCESS(ARTICLE.getLanding):
      return {
        ...state,
        articles: action.payload.data.data,
      };
    case SUCCESS(ARTICLE.getArticleBySlug):
      return {
        ...state,
        article: action.payload.data,
      };
    case SUCCESS(ARTICLE.getArticlesByPage):
      return {
        ...state,
        articles: action.payload.data.data,
      };

    default:
      return state;
  }
};
