import axios from "axios";

export const getLandingService = async () => {
  const res = await axios.get(`/api/article?status=TRENDING&page=1&size=3`)
  return res
};

export const getArticleBySlugService = async (payload: any) => {
  const res = await axios.get(`/api/article/slug/${payload}`)
  res.data.content = JSON.parse(res.data.content)
  return res
}

export const getArticlesByPageService = async (payload) => {
  const { page, size } = payload;
  const res = await axios.get(`/api/article?page=${page}&size=${size}`)
  return res
}