import { call, put, takeLatest, takeEvery, all } from "redux-saga/effects";
import { PRODUCT } from "../root/constants";
import { SUCCESS, FAILURE } from "store/utils/response-types.util";
import { filterService } from "store/service/product";

function* filter(action: any) {
  try {
    const newData = yield call(filterService, action.payload);
    yield put({
      type: SUCCESS(PRODUCT.filter),
      payload: newData,
    });
  } catch (error) {
    yield put({
      type: FAILURE(PRODUCT.filter),
      payload: error,
    });
  }
}

export function* watchProduct() {
  yield takeEvery(PRODUCT.filter, filter);
}

function* ProductWatcher() {
  yield all([watchProduct()]);
}

export default ProductWatcher;
