import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { IRootState } from 'store/reducers';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
// i18n
import { Translation } from "react-i18next"
// redux
import { filter } from 'store/actions/product';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: "'Montserrat', sans-serif;",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "'Montserrat', sans-serif;",
  },
  accordionRoot: {
    border: "0px",
    boxShadow: "none",
    "&.MuiAccordion-root:before": {
      backgroundColor: "#ffffff",
    }
  },
  summaryRoot: {
    borderLeft: "7px solid rgb(46,67, 151)",
    margin: "5px 0px"
  },
  boxRoot: {
    color: "rgb(46,67, 151)",
    '&$checked': {
      color: "rgb(46,67, 151)",
    },
  },
  labelRoot: {
    fontFamily: "'Montserrat', sans-serif;",
  }
}))

interface StateProps {
}

interface DispatchProps {
  filter: (params: any) => any;
}

interface ProductFilterProps {
  children?: any;
}

type Props = StateProps & DispatchProps & ProductFilterProps

const ProductFilter: React.FC<Props> = (props) => {
  const classes = useStyles();

  // category state and handling
  const [category, setCategory] = useState({
    may_sieu_am_noi_soi: false,
    tan_soi_duong_mat: false,
    may_phau_thuat: false,
    dien_nao_dien_co: false,
    mo_hinh: false,
  });

  const handleChangeCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = {
      may_sieu_am_noi_soi: false,
      tan_soi_duong_mat: false,
      may_phau_thuat: false,
      dien_nao_dien_co: false,
      mo_hinh: false,
    }
    newChecked[event.target.name] = event.target.checked
    setCategory(newChecked)
  };

  // country state and handling
  const [country, setCountry] = useState({
    Japan: false,
    China: false,
    USA: false,
    Germany: false,
    Italy: false,
    EU: false,
    Norway: false
  })

  const handleChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = {
      Japan: false,
      China: false,
      USA: false,
      Germany: false,
      Italy: false,
      EU: false,
      Norway: false
    }
    newChecked[event.target.name] = event.target.checked
    setCountry(newChecked)
  };

  // brand state and handling
  const [brand, setBrand] = useState({
    Laerdal: false,
    Olympus: false,
    Phillips: false,
    Natus: false,
    OPTIKON_2000_SPA: false,
    Ergoline_GmbH_Amed_Tec_GmbH_Geratherm_GmbH: false,
    Scientific_3B: false,
    ZOLL_Medical_Corporation: false,
  })

  const handleChangeBrand = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = {
      Laerdal: false,
      Olympus: false,
      Phillips: false,
      Natus: false,
      OPTIKON_2000_SPA: false,
      Ergoline_GmbH_Amed_Tec_GmbH_Geratherm_GmbH: false,
      Scientific_3B: false,
      ZOLL_Medical_Corporation: false,
    }
    newChecked[event.target.name] = event.target.checked
    setBrand(newChecked)
  };



  // send to redux
  useEffect(() => {
    // get the key that has value == true
    const queryObject = {
      page: 1,
      size: 6,
      category: Object.keys(category).filter(item => category[item] === true)[0],
      country: Object.keys(country).filter(item => country[item] === true)[0],
      brand: Object.keys(brand).filter(item => brand[item] === true)[0],
    }
    props.filter(queryObject)
  }, [category, country, brand])

  return (
    <Translation>
      {
        t => <div className={classes.root}>
          <Accordion classes={{
            root: classes.accordionRoot
          }}
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              classes={{
                root: classes.summaryRoot,
              }}
            >
              <Typography className={classes.heading}>Category</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={category.may_sieu_am_noi_soi}
                        onChange={handleChangeCategory}
                        name="may_sieu_am_noi_soi"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="Máy siêu âm - nội soi"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={category.tan_soi_duong_mat}
                        onChange={handleChangeCategory}
                        name="tan_soi_duong_mat"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="Tán sỏi đường mật"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={category.may_phau_thuat}
                        onChange={handleChangeCategory}
                        name="may_phau_thuat"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="Máy phẫu thuật"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={category.dien_nao_dien_co}
                        onChange={handleChangeCategory}
                        name="dien_nao_dien_co"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="Điện não - Điện cơ"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={category.mo_hinh}
                        onChange={handleChangeCategory}
                        name="mo_hinh"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="Mô hình"
                  />
                </FormGroup>
              </FormControl>
            </AccordionDetails>
          </Accordion>

          <Accordion classes={{
            root: classes.accordionRoot
          }}
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              classes={{
                root: classes.summaryRoot
              }}
            >
              <Typography className={classes.heading}>Country</Typography>
            </AccordionSummary>
            <AccordionDetails>

              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={country.Japan}
                        onChange={handleChangeCountry}
                        name="Japan"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="Japan"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={country.China}
                        onChange={handleChangeCountry}
                        name="China"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="China"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={country.USA}
                        onChange={handleChangeCountry}
                        name="USA"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="USA"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={country.Germany}
                        onChange={handleChangeCountry}
                        name="Germany"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="Germany"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={country.Italy}
                        onChange={handleChangeCountry}
                        name="Italy"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="Italy"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={country.EU}
                        onChange={handleChangeCountry}
                        name="EU"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="EU"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{
                          root: classes.boxRoot
                        }}
                        color="default"
                        checked={country.Norway}
                        onChange={handleChangeCountry}
                        name="Norway"
                      />
                    }
                    classes={{
                      label: classes.labelRoot,
                    }}
                    label="Norway"
                  />
                </FormGroup>
              </FormControl>

            </AccordionDetails>
          </Accordion>

          <Accordion classes={{
            root: classes.accordionRoot
          }}
            defaultExpanded={true}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              classes={{
                root: classes.summaryRoot
              }}
            >
              <Typography className={classes.heading}>Brand</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        root: classes.boxRoot
                      }}
                      color="default"
                      checked={brand.Laerdal}
                      onChange={handleChangeBrand}
                      name="Laerdal"
                    />
                  }
                  classes={{
                    label: classes.labelRoot,
                  }}
                  label="Laerdal"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        root: classes.boxRoot
                      }}
                      color="default"
                      checked={brand.Olympus}
                      onChange={handleChangeBrand}
                      name="Olympus"
                    />
                  }
                  classes={{
                    label: classes.labelRoot,
                  }}
                  label="Olympus"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        root: classes.boxRoot
                      }}
                      color="default"
                      checked={brand.Phillips}
                      onChange={handleChangeBrand}
                      name="Phillips"
                    />
                  }
                  classes={{
                    label: classes.labelRoot,
                  }}
                  label="Phillips"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        root: classes.boxRoot
                      }}
                      color="default"
                      checked={brand.Natus}
                      onChange={handleChangeBrand}
                      name="Natus"
                    />
                  }
                  classes={{
                    label: classes.labelRoot,
                  }}
                  label="Natus"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        root: classes.boxRoot
                      }}
                      color="default"
                      checked={brand.OPTIKON_2000_SPA}
                      onChange={handleChangeBrand}
                      name="OPTIKON_2000_SPA"
                    />
                  }
                  classes={{
                    label: classes.labelRoot,
                  }}
                  label="OPTIKON 2000 SPA"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        root: classes.boxRoot
                      }}
                      color="default"
                      checked={brand.Ergoline_GmbH_Amed_Tec_GmbH_Geratherm_GmbH}
                      onChange={handleChangeBrand}
                      name="Ergoline_GmbH_Amed_Tec_GmbH_Geratherm_GmbH"
                    />
                  }
                  classes={{
                    label: classes.labelRoot,
                  }}
                  label="Ergoline GmbH, Amed Tec GmbH, Geratherm GmbH"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        root: classes.boxRoot
                      }}
                      color="default"
                      checked={brand.Scientific_3B}
                      onChange={handleChangeBrand}
                      name="Scientific_3B"
                    />
                  }
                  classes={{
                    label: classes.labelRoot,
                  }}
                  label="3B Scientific"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      classes={{
                        root: classes.boxRoot
                      }}
                      color="default"
                      checked={brand.ZOLL_Medical_Corporation}
                      onChange={handleChangeBrand}
                      name="ZOLL_Medical_Corporation"
                    />
                  }
                  classes={{
                    label: classes.labelRoot,
                  }}
                  label="ZOLL Medical Corporation"
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>

        </div >
      }
    </Translation>
  )
}


const mapStateToProps = ({ product }: IRootState) => ({
})

const mapDispatchToProps = {
  filter
}


export default connect<StateProps, DispatchProps, ProductFilterProps>(mapStateToProps, mapDispatchToProps)(ProductFilter);