import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";
import { LOCALES_EN } from "locales/en/common";
import { LOCALES_VI } from "locales/vn/common";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
const resources = {
  en: {
    translation: LOCALES_EN
  },
  vi: {
    translation: LOCALES_VI
  }
};

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "vi", // use en if detected lng is not available
    saveMissing: true, // send not translated keys to endpoint
    resources,
    lng: "vi", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;