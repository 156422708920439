import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { IRootState } from 'store/reducers';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button"
import CustomFooter from 'components/CustomFooter/custom-footer.component';
import NewsCard from 'components/Card/NewsCard';
import GoogleMaps from 'components/Maps/GoogleMaps';
// i18n
import { Translation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: "'Montserrat', sans-serif;",
    color: "rgb(46,67, 151)",
    [theme.breakpoints.down('xs')]: {
      padding: "calc(175px + 3.5vw) 15px 0px 15px",
    },
    [theme.breakpoints.up('xs')]: {
      padding: "calc(148px + 4vw) 15px 0px 15px",
    },
  },
  container: {
    height: "100%",
    maxWidth: 1300,
    margin: "auto",
    paddingTop: 15,
    display: "flex",
    columnGap: 30,
    justifyContent: "center",
    [theme.breakpoints.down('sm')]: {
      display: "block",
    },
  },
  cardContainer: {
    width: "100%",
    margin: "0px !important",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    },
  },
  mapsContainer: {
    width: "550px",
    margin: "0px 15px 20px 15px",
    [theme.breakpoints.down('sm')]: {
      margin: "auto"
    },
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    },
  },
  ourLocation: {
    marginBottom: 15,
    marginTop: 15
  },
  location: {
    marginBottom: 15
  },
  infoContainer: {
    // marginTop: 15,
    [theme.breakpoints.down('sm')]: {
      textAlign: "center"
    },
  }
}))

interface StateProps {
}

interface DispatchProps {
}

interface ContactUsProps {
  children?: any;
}

type Props = StateProps & DispatchProps & ContactUsProps

const ContactUsPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Translation>
      {
        t => <div>
          <div className={classes.root}>
            <div className={classes.container}>
              <div className={classes.mapsContainer}>
                <div>
                  <h4 className={classes.ourLocation}>{t('ourLocation')}</h4>
                  <div className={classes.location}>{t('location')}</div>
                </div>
                <GoogleMaps />
              </div>
              <div className={classes.infoContainer}>
                <div>
                  <h4 className={classes.ourLocation}>{t('phoneNumber')}</h4>
                  <div className={classes.location}>(+84)904 177 211</div>
                </div>
                <div>
                  <h4 className={classes.ourLocation}>EMAIL:</h4>
                  <div className={classes.location}>contact.us@mstc.com</div>
                </div>
              </div>
            </div>
          </div>
          <CustomFooter />
        </div >
      }
    </Translation>
  )
}

const mapStateToProps = ({ article }: IRootState) => ({
})

const mapDispatchToProps = {}


export default connect<StateProps, DispatchProps, ContactUsProps>(mapStateToProps, mapDispatchToProps)(ContactUsPage);