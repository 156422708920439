import { createStyles, makeStyles } from "@material-ui/core"
import Button from "components/CustomButtons/Button";
import React, { useState, useEffect, useLayoutEffect } from "react"
import EditorJs from 'react-editor-js';
import { connect } from "react-redux";
import { EDITOR_JS_TOOLS } from "./tools"

const useStyles = makeStyles(() => ({
  editorContainer: {
    width: "100%",
    // minHeight: "400px",
    // marginTop: 40,
    // border: "1px solid #00000026"
  }
}))


const TextEditor = (props) => {
  const { initialState } = props
  const classes = useStyles();

  const [editorInstance, setEditorInstance] = useState();
  const [textEditor, setTextEditor] = useState({})

  // useLayoutEffect(() => {
  //   if (typeof initialState !== "string") {
  //     if (initialState.blocks.length > 0) {
  //       setTextEditor(initialState)
  //     }
  //   }
  // }, [initialState])


  return (
    <div className={classes.editorContainer}>
      {
        props.article.content && <EditorJs
          holder="editorjs"
          data={props.article.content}
          tools={EDITOR_JS_TOOLS}
          readOnly={true}
          onReady={() => {
            console.log("Editor is ready!")
          }}
          instanceRef={(instance) => setEditorInstance(instance)}
        >
          <div id="editorjs"></div>
        </EditorJs>
      }
    </div>
  )
}

const mapStateToProps = ({ article }) => ({
  article: article.article
})

const mapDispatchToProps = {

}



export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);