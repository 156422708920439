
export const BUTTON = {
  buttonClicked: 'button/BUTTON_CLICKED'
}

export const EMAIL = {
  subscribe: 'email/SUBSCRIBE'
}

export const ARTICLE = {
  getLanding: 'articles/GET_LANDING',
  getArticleBySlug: 'articles/GET_ARTICLE_BY_SLUG',
  getArticlesByPage: 'articles/GET_ARTICLES_BY_PAGE',
}

export const PRODUCT = {
  filter: 'PRODUCT/FILTER'
}