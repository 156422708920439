import { ARTICLE } from "store/root/constants";

export const getLanding = () => {
  return {
    type: ARTICLE.getLanding,
  };
};

export const getArticleBySlug = (request: string) => {
  return {
    type: ARTICLE.getArticleBySlug,
    payload: request
  }
}

export const getArticlesByPage = (request: any) => {
  return {
    type: ARTICLE.getArticlesByPage,
    payload: request
  }
}