import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { subscribe } from "store/actions/email"

import NotificationCard from 'components/NotificationCard/notification-card.component';
import CustomInput from 'components/CustomInput/CustomInput';
import '../../assets/scss/component-stylings/custom-footer.styles.scss';
import EmailIcon from '../../assets/img/email-icon.png'
import PhoneIcon from '../../assets/img/phone-icon.png'
import AddressIcon from '../../assets/img/address-icon.png'
import FacebookIcon from '../../assets/img/facebook-icon.png'
import MessengerIcon from '../../assets/img/messenger-icon.png'
import TwitterIcon from '../../assets/img/twitter-icon.png'
import CustomButton from 'components/CustomButtons/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

// i18n
import { Translation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	inputRoot: {
		width: "80%",
		marginBottom: 15,
	},
	inputProps: {
		color: "#ffffff"
	}
}))

// Import anim

const CustomFooter = (props) => {
	const classes = useStyles();
	const { userEmail, buttonOnSubmit } = props
	const [onSubmit, setOnSubmit] = useState(false)

	const matches = useMediaQuery('(max-width:768px)');
	const xsMatches = useMediaQuery('(max-width:375px)')


	const [email, setEmail] = useState("")

	const handleChangeInput = (e) => {
		setEmail(e.target.value)
	}

	const handleFormSubmit = (e) => {
		e.preventDefault()
		if (email !== "") {
			props.subscribe(email)

			setOnSubmit(!onSubmit)
		}
	}

	const handleNotiDoneClick = () => {
		setOnSubmit(!onSubmit)
	}

	return (
		<Translation>
			{
				t =>
					<div className='footer' >
						<span className='footer-title'>{t("company")}</span>
						{
							matches || <div className='footer-contact-us-details-container'>
								<span className='footer-contact-us'>{t('contactUs')}</span>
								<p className='footer-contact-us-details'><img className='footer-details-icon' src={EmailIcon} />contact.us@mstc.com</p>
								<p className='footer-contact-us-details'><img className='footer-details-icon' src={PhoneIcon} />(+84) 904 177 211</p>
								<p className='footer-contact-us-details'><img className='footer-details-icon' src={AddressIcon} />{t('location')}</p>
							</div>
						}

						<span className='footer-copyright'>Copyright ©2021 MSTC IES.,JSC</span>

						{
							xsMatches || <div className='footer-social-media'>
								<img className='social-media-icon' src={FacebookIcon} alt="" />
								<img className='social-media-icon' src={MessengerIcon} alt="" />
								<img className='social-media-icon' src={TwitterIcon} alt="" />
							</div>
						}

						<div className='footer-submit-container'>
							<div className='footer-submit'>
								<span className='footer-submit-title'>{t('subscribe')}</span>
								<form className='footer-form' onSubmit={handleFormSubmit}>
									{/* <input
										className='footer-submit-input'
										type="email"
										value={email}
										required
										onChange={handleChangeInput}
									/> */}
									<TextField
										InputProps={{
											value: email,
											onChange: handleChangeInput,
											className: classes.inputProps
										}}
										classes={{
											root: classes.inputRoot,
										}}
										type="email"
										fullWidth
										placeholder={t('enterEmail')}
									/>
									{/* <label className={`${userEmail ? 'shrink' : null} input-label`}>{t('enterEmail')}</label> */}
									<CustomButton className='footer-submit-btn' onClick={handleFormSubmit}>
										{t('submit')}
									</CustomButton>
								</form>
							</div>
						</div>

						<NotificationCard handleNotiDoneClick={handleNotiDoneClick} onSubmit={onSubmit} />
					</div>
			}
		</Translation>
	)
}

const mapStateToProps = ({ article }) => ({
})

const mapDispatchToProps = {
	subscribe
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomFooter);