import React from "react";
// nodejs library to set properties for components
// import PropTypes from "prop-types";
// @material-ui/core components
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Grid, {GridSize} from "@material-ui/core/Grid";

const useStyles = makeStyles((theme: Theme) => createStyles({
  grid: {
    position: "relative",
    width: "100%",
    minHeight: "1px",
    paddingRight: "15px",
    paddingLeft: "15px",
    flexBasis: "auto",
  },
}))

interface GridItemProps {
  children?: any;
  className?: string;
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
}

const GridItem: React.FC<GridItemProps> = (props) => {
  const classes = useStyles();
  const { children, className, xs, lg, md, sm } = props;

  return (
    <Grid item className={classes.grid + " " + className}
      xs={xs}
      lg={lg}
      md={md}
      sm={sm}
    >
      {children}
    </Grid>
  );
}

export default GridItem;