export const LOCALES_VI = {
  company: "Công ty Cổ phần Đầu tư Thương mại Dịch vụ Kỹ thuật MSTC",
  companyShort: "Công ty Cổ phần MSTC",
  we: "HÃY",
  sell: "MUA",
  the: "SỰ",
  safety: "AN TOÀN",
  homeHeader: "Trang chủ",
  ourStoriesHeader: "Giới thiệu",
  productsHeader: "Sản phẩm",
  newsHeader: "Tin tức",
  contactUsHeader: "Liên hệ",
  learnMore: "Tìm hiểu thêm",
  whoAreWe: "VỀ CHÚNG TÔI",
  aboutUsContent: "Được thành lập năm 2016, MSTC là một trong những công ty hàng đầu trong lĩnh vực cung cấp thiết bị và vật tư y tế ở Việt Nam. Chúng tôi mong sẽ mang đến cho bạn trải nghiệm chuyên nghiệp và thoải mái nhất.",
  whatWeDo: "SẢN PHẨM",
  medicalEquipmentTrading: "Hệ thống khử trùng, diệt khuẩn (Gettinge)",
  medicalConsumableSupplies: "Hệ thống dán nhãn thuốc",
  maintenanceServices: "Hệ thống khí y tế và nội thất phòng mổ",
  consultant: "Hệ thống vận chuyển mẫu của Đức",
  investment: "Hệ thống khí y tế và cánh tay treo trần Deager (Đức)",
  trainingCooperation: "",
  ourGoals: "MỤC TIÊU",
  goal_1: "Trở thành cung cấp các dịch vụ và thiết bị y tế chuyên nghiệp, hiệu quả",
  goal_2: "Lựa chọn số 1 của các đối tác, bệnh viện, và công ty tại Việt Nam",
  goal_3: "Thúc đẩy hợp tác và phát triển trong lĩnh vực đào tạo y tế",
  goal_4: "Cung cấp giải pháp đột phá và sáng tạo trong quản lý bệnh viện",
  contactUs: "Kết nối với chúng tôi",
  subscribe: "Đăng ký để nhận thông tin từ chúng tôi",
  submit: "Gửi",
  location: "Phòng 606, Toà nhà Indochina Plaza Hanoi (IPH), 241 Xuân Thủy, Dịch Vọng Hậu, Cầu Giấy, Hà Nội",
  enterEmail: "Nhập email của bạn",
  ourPartners: 'ĐỐI TÁC',
  aboutUs: "Về chúng tôi",
  newsLanding: "TIN TỨC",
  ourLocation: "ĐỊA CHỈ TRỤ SỞ:",
  phoneNumber: "SỐ ĐIỆN THOẠI:",
  bannerAbout: "VỀ CHÚNG TÔI",
  bannerProduct: "SẢN PHẨM",
  bannerNews: "TIN TỨC",
  noProduct: "Không có sản phẩm phù hợp với yêu cầu",
  findOutMore: "Tìm hiểu thêm",
  // about us text
  aboutUsHeader: "GIỚI THIỆU VỀ MSTC",
  aboutUsParagraph1: "CÔNG TY CỔ PHẦN ĐẦU TƯ THƯƠNG MẠI DỊCH VỤ KỸ THUẬT MSTC được biết đến là công ty dẫn đầu trong lĩnh vực phân phối thiết bị y tế tại Việt Nam; chuyên phân phối các dòng sản phẩm chất lượng cao thuộc các thương hiệu hàng đầu thế giới như Phillips (Mỹ), Natus (Mỹ), 3B scientific (Đức), Olympus (Nhật Bản),… Đối với chúng tôi, trải nghiệm của khách hàng luôn được ưu tiên. Vì vậy, các dịch vụ chăm sóc khách sau bán hàng như bảo hành, bảo trì, sửa chữa và đào tạo người sử dụng là một phần thiết yếu trong triết lý kinh doanh của chúng tôi. Chúng tôi đặt lên hàng đầu sự an tâm, tin tưởng các bác sĩ và nhân viên y tế về dịch vụ hỗ trợ kỹ thuật chất lượng cao trong thời gian ngắn nhất. Đội ngũ MSTC đang nỗ lực làm việc mỗi ngày với ước mong có thể mang lại những thay đổi sâu sắc trong các hoạt động khám chữa bệnh và sức khỏe cho người dân tại Việt Nam.",
  aboutUsParagraph2: "Cùng với sự phát triển của hệ thống Y tế, Công ty MSTC triển khai dịch vụ trên đa dạng các ngành hàng: Cứu Mê Hồi Sản, Siêu  m, Chấn Thương Chỉnh Hình, Ngoại Khoa, Kiểm Soát Nhiễm Khuẩn, Hạ Tầng,..và sẵn sàng bổ sung nhiều ngành hàng mới trong tương lai.",
  aboutUsParagraph3: "Suốt quá trình thành lập và phát triển, MSTC đã và đang trở thành một đối tác có tiềm lực mạnh, đáng tin cậy và có năng lực triển khai dự án y tế lớn, được nhiều Hiệp hội Y khoa và Bộ Y tế tin tưởng, lựa chọn là đối tác của nhiều chương trình đào tạo, hội thảo, hội nghị hàng năm.",
  aboutUsParagraph4: "Tìm hiểu thêm tại "
};

// Room 606, Indochina Plaza Hanoi,241 Xuan Thuy Street, Cau Giay District, Ha Noi, Viet nam