import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { IRootState } from 'store/reducers';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from "components/CustomButtons/Button"
import CustomFooter from 'components/CustomFooter/custom-footer.component';
import productBanner from "assets/img/product-banner.jpg"
import ProductCard from 'components/Card/ProductCard';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// i18n
import { Translation } from "react-i18next"
import ProductFilter from './ProductFilter';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fontFamily: "'Montserrat', sans-serif;",
    [theme.breakpoints.down('xs')]: {
      padding: "calc(175px + 2.5vw) 0px 0px 0px",
    },
    [theme.breakpoints.up('xs')]: {
      padding: "calc(140px + 2.5vw)  0px 0px 0px",
    },
  },
  banner: {
    width: "100%",
    height: "350px",
    backgroundImage: `url(${productBanner})`,
    backgroundRepeat: "no-repeat, repeat;",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 35,
    fontWeight: "bold",
    color: "#ffffff",
    backgroundSize: "100%",
    [theme.breakpoints.down('xs')]: {
      height: "200px",
    },
  },
  container: {
    height: "100%",
    maxWidth: 1100,
    margin: "auto",
    padding: "30px 15px",
    display: "flex",
    columnGap: 30,
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column-reverse"
    },
  },
  cardRow: {
    display: "flex",
    columnGap: 15
  }
}))

interface StateProps {
  products: any;
}

interface DispatchProps {
}

interface ProductsPageProps {
  children?: any;
}

type Props = StateProps & DispatchProps & ProductsPageProps

const ProductsPage: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery('(max-width:768px)');

  const [rows, setRows] = useState([])
  const [numInRow, setNumInRow] = useState(3)

  // split to rows with different elements on each row
  const splitToRows = (arr: Array<any>, num: number, result: Array<any>) => {
    if (arr.length < num) {
      if (arr.length > 0) {
        for (let i = 0; i < num; i++) {
          if (!arr[i]) {
            arr[i] = -1;
          }
        }
        result.push(arr)
      }
      return result;
    } else {
      result.push(arr.slice(0, num))

      return splitToRows(arr.slice(num), num, result)
    }
  }

  useEffect(() => {
    if (props.products.length > 0) {
      setRows(splitToRows(props.products, numInRow, []))
    }
  }, [props.products])

  useEffect(() => {
    if (matches) {
      setNumInRow(2)
    } else {
      setNumInRow(3)
    }
  }, [matches])

  return (
    <Translation>
      {
        t => <div>
          <div className={classes.root}>
            <div className={classes.banner}>
              {t('bannerProduct')}
            </div>
            <div className={classes.container}>
              <GridItem md={3} sm={3} xs={12}>
                <ProductFilter />
              </GridItem>
              <GridItem md={9} sm={9} xs={12}>
                {
                  props.products.length > 0
                    ? <div>
                      {
                        rows.map((row, i) => <GridItem key={i} md={12} sm={12} xs={12} className={classes.cardRow}>
                          {
                            row.map((item, itemKey) => item !== -1
                              ? <GridItem key={itemKey} md={12} sm={12} xs={12}>
                                <ProductCard content={item} />
                              </GridItem>
                              : <GridItem key={itemKey}></GridItem>)
                          }
                        </GridItem>)
                      }
                    </div>
                    : <div>
                      <div>{t('noProduct')}</div>
                    </div>
                }
              </GridItem>
            </div>
          </div>
          <CustomFooter />
        </div >
      }
    </Translation >
  )
}

// contact.us@mstc.com
// (+84)904 177 211

const mapStateToProps = ({ product }: IRootState) => ({
  products: product.products,
  page: product.page,
  size: product.size,
  total: product.total
})

const mapDispatchToProps = {
}


export default connect<StateProps, DispatchProps, ProductsPageProps>(mapStateToProps, mapDispatchToProps)(ProductsPage);