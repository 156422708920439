import { call, put, takeLatest, takeEvery, all, ActionPattern } from "redux-saga/effects";
import { ARTICLE } from "../root/constants";
import { SUCCESS, FAILURE } from "store/utils/response-types.util";
import { getArticleBySlugService, getArticlesByPageService, getLandingService } from "store/service/article";

function* getLanding(action: any) {
  try {
    const newData = yield call(getLandingService);
    yield put({
      type: SUCCESS(ARTICLE.getLanding),
      payload: newData,
    });
  } catch (error) {
    yield put({
      type: FAILURE(ARTICLE.getLanding),
      payload: error,
    });
  }
}

function* getArticleBySlug(action: any) {
  try {
    const newData = yield call(getArticleBySlugService, action.payload);
    yield put({
      type: SUCCESS(ARTICLE.getArticleBySlug),
      payload: newData,
    });
  } catch (error) {
    yield put({
      type: FAILURE(ARTICLE.getArticleBySlug),
      payload: error,
    });
  }
}

function* getArticlesByPage(action: any) {
  try {
    const newData = yield call(getArticlesByPageService, action.payload);
    yield put({
      type: SUCCESS(ARTICLE.getArticlesByPage),
      payload: newData,
    });
  } catch (error) {
    yield put({
      type: FAILURE(ARTICLE.getArticlesByPage),
      payload: error,
    });
  }
}

export function* watchArticle() {
  yield takeEvery(ARTICLE.getLanding, getLanding);
  yield takeEvery(ARTICLE.getArticleBySlug, getArticleBySlug);
  yield takeEvery(ARTICLE.getArticlesByPage, getArticlesByPage);
}

function* ArticleWatcher() {
  yield all([watchArticle()]);
}

export default ArticleWatcher;
