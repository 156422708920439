import React, { useRef, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import GridContainer from 'components/Grid/GridContainer';
import Button from "components/CustomButtons/Button"
import { Translation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import NewsCard from 'components/Card/NewsCard';
import { getLanding } from 'store/actions/article';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "100%",
    margin: "0px !important",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
    },
  }
}))


const LandingPage = (props) => {
  const classes = useStyles();

  useEffect(() => {
    props.getLanding()
  }, [])

  return (
    <Translation>
      {
        t =>
          <GridContainer className={classes.cardContainer}>
            {
              props.articles.length > 0 && props.articles.map((article, i) => <NewsCard key={i} content={article} />)
            }
          </GridContainer>
      }
    </Translation >
  )
}


const mapStateToProps = ({ article }) => ({
  articles: article.articles
})

const mapDispatchToProps = {
  getLanding
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);