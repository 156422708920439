import { combineReducers } from "redux";
import email, { EmailState } from "./email";
import article, { ArticleState } from "./article";
import product, { ProductState } from "./product";
export interface IRootState {
  readonly email: EmailState,
  readonly article: ArticleState,
  readonly product: ProductState,
}

const rootReducers = combineReducers<IRootState>({
  email,
  article,
  product
});

export default rootReducers;