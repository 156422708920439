import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface ScrollToTopProps {
  children?: any
}

const ScrollToTop: React.FC<ScrollToTopProps> = (props) => {
  const location = useLocation();
  useEffect(() => {
    console.log("hahah")
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    // window.scrollTo({top:0})
  }, [location]);

  return <div id="scroller">{props.children}</div>
};

export default ScrollToTop;