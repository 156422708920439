import React, { useRef, useState, useEffect } from 'react';
import '../../assets/scss/component-stylings/notification-card.styles.scss';
import NewsletterDone from 'assets/img/newsletter-done.png';
import { gsap } from 'gsap';

const NotificationCard = ({ onSubmit, handleNotiDoneClick }) => {
	const notiCardConRef = useRef()
	const notiCardRef = useRef()

	useEffect(() => {
		onSubmit ?
			(
				gsap.to([notiCardConRef.current], { duration: 0, display: 'flex' }),
				gsap.to([notiCardConRef.current], { duration: .3, opacity: 1 }),
				gsap.to([notiCardRef.current], { delay: .35, duration: .3, opacity: 1 }),
				gsap.to([notiCardRef.current], { delay: .3, duration: .5, y: 0, ease: 'back.out(3)' })
			)
			:
			(
				gsap.to([notiCardConRef.current], { delay: .8, duration: 0, display: 'none' }),
				gsap.to([notiCardConRef.current], { delay: .3, duration: .5, opacity: 0 }),
				gsap.to([notiCardRef.current], { delay: .3, duration: .5, opacity: 0 }),
				gsap.to([notiCardRef.current], { duration: .5, y: '-30vh', ease: 'back.in(3)' })
			)
	}, [onSubmit])

	return (
		<div className='notification-card-container' ref={notiCardConRef}>
			<div className='notification-card' ref={notiCardRef}>
				<span className='notification-card-text text-1'>THANK YOU FOR YOUR INTEREST!</span>
				<span className='notification-card-text text-2'>We&apos;ll contact you very soon</span>
				<img onClick={handleNotiDoneClick} className='notification-card-done' src={NewsletterDone} alt="" />
				<div className='newsletter-img' />
			</div>
		</div>
	)
}

export default NotificationCard