import React, { useRef, useEffect, useState } from 'react';

import { connect } from 'react-redux';
import '../../../assets/scss/component-stylings/landing-page.styles.scss';
import GridContainer from 'components/Grid/GridContainer';
import Button from "components/CustomButtons/Button"
import CustomFooter from 'components/CustomFooter/custom-footer.component';
// import { ReactComponent as Curve } from '../../../assets/svg/curve.svg';
import LandingImg1 from '../../../assets/img/mstc-landing-1.png'
import LandingImg2 from '../../../assets/img/mstc-landing-2.png'
import LandingImg3 from '../../../assets/img/mstc-landing-3.png'
import partnerImg1 from "assets/img/goldengate.png"
import partnerImg2 from "assets/svg/dragger.svg"
import gsap from 'gsap'
import CustomEase from "gsap/CustomEase"
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useHistory } from "react-router-dom"

gsap.registerPlugin(CustomEase, ScrollTrigger);
import { Translation } from 'react-i18next';
import { makeStyles, useMediaQuery } from '@material-ui/core';
import NewsCard from 'components/Card/NewsCard';
import { getLanding } from 'store/actions/article';
import LandingArticles from './LandingArticles';

const useStyles = makeStyles((theme) => ({
	imgContainer: {
		display: "flex",
		justifyContent: "center"
	},
	imgMobile: {
		width: "100px",
		height: "auto"
	},
	logoCard: {
		padding: "15px",
		background: "#fff",
		borderStyle: "double",
		borderColor: "rgb(46,67, 151)",
		display: "flex",
		overflow: "hidden",
		alignItems: "center",
		justifyContent: "center",
		width: "100px",
		marginTop: 15,
		height: "60px",
		// maxHeight: ""
		[theme.breakpoints.up('md')]: {
			width: "160px",
			height: "90px",
		},
	},
	logoImg: {
		width: "100px"
	},
	cardContainer: {
		width: "100%"
	}
}))


const LandingPage = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const matches = useMediaQuery('(max-width:992px)')
	const matchesPhone = useMediaQuery('(max-width:650px)')

	const imgTrigger1 = useRef()
	const img1 = useRef()
	const content1 = useRef()

	const imgTrigger2 = useRef()
	const img2 = useRef()
	const content2 = useRef()

	const imgTrigger3 = useRef()
	const s4Circle = useRef()
	const s4Square = useRef()

	const heading1 = useRef()
	const heading2 = useRef()
	const heading3 = useRef()

	const headerTrigger = useRef()

	useEffect(() => {
		if (!matches) {
			let imgTl1 = gsap.timeline({
				scrollTrigger: {
					trigger: [imgTrigger1.current],
					start: 'bottom 80%',
					once: true,
					// scrub: 1, //optimize
				}
			})
			imgTl1.from([heading1.current], { scaleX: 0, duration: .7, ease: 'power4.inOut' })
				.from([img1.current], { x: -200, opacity: 0, duration: 1, ease: 'power4.out' }, .4)
				.from([content1.current], { x: 200, opacity: 0, duration: 1, ease: 'power4.out' }, .4)
				.from('.s2-button', { y: 100, opacity: 0, duration: .9, ease: 'power4.in' }, .2)


			let imgTl2 = gsap.timeline({
				scrollTrigger: {
					trigger: [imgTrigger2.current],
					start: 'bottom 80%',
					once: true,
					// scrub: 1, //optimize
				}
			})
			imgTl2.from([heading2.current], { scaleX: 0, duration: .7, ease: 'power4.inOut' })
				.from([img2.current], { x: 200, opacity: 0, duration: 1, ease: 'power4.out' }, .4)
				.from([content2.current], { x: -200, opacity: 0, duration: 1, ease: 'power4.out' }, .4)
				.from('.s3-button', { y: 100, opacity: 0, duration: .9, ease: 'power4.in' }, .2)

			let imgTl3 = gsap.timeline({
				scrollTrigger: {
					trigger: [imgTrigger3.current],
					start: 'bottom 75%',
					once: true,
					// scrub: 1, //optimize
				}
			})
			imgTl3.from([heading3.current], { scaleX: 0, duration: .7, ease: 'power4.inOut' })
				.from([s4Circle.current], { scaleY: 0, duration: 2, ease: 'power4.out' }, .01)
				.from([s4Square.current], { scaleY: 0, duration: 2, ease: 'power4.out' }, .29)

			let headerTl = gsap.timeline({
				scrollTrigger: {
					trigger: [headerTrigger.current],
					start: 'top 15%',
					scrub: true,
				}
			})
			headerTl.to('.header', { duration: .1, backgroundColor: 'white', webkitBoxShadow: '-1px 5px 10px 5px rgba(0,0,0,0.35)' })
		}
	}, [])

	// useEffect(() => {
	// 	props.getLanding()
	// }, [])

	// effect change page
	const [prevLoc, setNextLoc] = useState('/')

	const curtain1 = useRef()
	const curtain2 = useRef()

	const handleRouteSwitch = (loc) => {

		setNextLoc(loc)

		let curtainTl = gsap.timeline()
		curtainTl.to([curtain1.current], { duration: .9, scaleY: 1, ease: 'power4.inOut' })
			.to([curtain2.current], { duration: .9, scaleY: 1, ease: 'power4.inOut' }, .35)
			.to([curtain1.current], { webkitTransformOrigin: 'bottom', duration: 0 }, 1.25)
			.to([curtain2.current], { webkitTransformOrigin: 'bottom', duration: 0 }, 1.25)
			.to([curtain2.current], { duration: .9, scaleY: 0, ease: 'power4.inOut' }, 1.25)
			.to([curtain1.current], { duration: .9, scaleY: 0, ease: 'power4.inOut' }, 1.6)
			.to([curtain1.current], { webkitTransformOrigin: 'top', duration: 0 })
			.to([curtain2.current], { webkitTransformOrigin: 'top', duration: 0 })
		curtainTl.pause()

		loc !== history.location.pathname ?
			(
				curtainTl.play(),
				setTimeout(() => {
					history.push(loc)
				}, 700)
			)
			:
			(
				null
			)
	}

	useEffect(() => {
		document.getElementsByTagName("body")[0].style.overflowY = "auto"
	}, [])

	return (
		<Translation>
			{
				t =>
					<>
						<div className='loading-curtain-1' ref={curtain1}></div>
						<div className='loading-curtain-2' ref={curtain2}></div>
						<div>
							<div className='landing-page'>
								<div className='section-1'>
									{
										matches || <>
											<div className='vertical-div' ></div>
											<span className='overlay-text'>MEDICAL&nbsp;EQUIPMENT</span>
											<img className='landing-img-1' src={LandingImg1} alt="" />
											<div className='vertical-bar'></div>
										</>
									}
									<div className='slogan-container'>
										{/* {
											matches === true
												? <span className='slogan-big' ref={headerTrigger}><span className='slogan-big-blue'>MSTC</span></span>
												: <div style={{ display: "flex" }}>
													<span className='slogan-big' ref={headerTrigger}><span className='slogan-big-blue'>MSTC</span>
													</span>
												</div>
										} */}
										{/* <span className='slogan-mid'>{t('the')} <span className='slogan-small-blue'>&nbsp;{t('safety')}</span></span> */}
										<span className='slogan-small'>{t('aboutUsContent')}</span>
									</div>
									<Button onClick={() => {
										window.scrollTo(0, 0)
										history.push('/about')
									}} className='s1-button'>{t('learnMore')}</Button>
									{/* {
									matches && <div className={classes.imgContainer}>
										<img className={classes.imgMobile} src={LandingImg1} alt="" />
									</div>
								} */}
								</div>

								<div className='section-2'>
									<div className='s2-title-container'>
										<hr className='s2-title-hr' ref={heading1} />
										<span className='s2-title' ref={imgTrigger1}>{t('whoAreWe')}</span>
									</div>
									<img className='landing-img-2' src={LandingImg2} alt="" ref={img1} />
									<span className='s2-content' ref={content1}>{t('aboutUsContent')}</span>
									<Button onClick={() => {
										history.push('/about')
									}} className='s2-button'>{t('learnMore')}</Button>
								</div>

								<div className='section-3'>
									<div className='s3-title-container'>
										<hr className='s3-title-hr' ref={heading2} />
										<span className='s3-title' ref={imgTrigger2}>{t('whatWeDo')}</span>
									</div>
									<img className='landing-img-3' src={LandingImg3} alt="" ref={img2} />
									<div className='s3-content' ref={content2}>
										<span className='content-s'>{t('medicalEquipmentTrading')}</span>
										<span className='content-s'>{t('medicalConsumableSupplies')}</span>
										<span className='content-s'>{t('maintenanceServices')}</span>
										<span className='content-s'>{t('consultant')}</span>
										<span className='content-s'>{t('investment')}</span>
										<span className='content-s'>{t('trainingCooperation')}</span>
									</div>
									<Button onClick={() => {
										history.push('/products')
									}} className='s3-button'>{t('learnMore')}</Button>
								</div>


								<div className='section-4'>
									<div className='s4-title-container'>
										<hr className='s4-title-hr' ref={heading3} />
										<span className='s4-title' ref={imgTrigger3}>{t('ourGoals')}</span>
									</div>
									<div className='s4-content'>
										<span className='content-s'><span className='content-index'>1</span>{t('goal_1')}</span>
										<span className='content-s'><span className='content-index'>2</span>{t('goal_2')}</span>
										<span className='content-s'><span className='content-index'>3</span>{t('goal_3')}</span>
										<span className='content-s'><span className='content-index'>4</span>{t('goal_4')}</span>
									</div>
									<div className='s4-circle' ref={s4Circle} />
									<div className='s4-square' ref={s4Square} />
								</div>

								<div className='section-5'>
									<div className='s4-title-container'>
										<hr className='s4-title-hr' ref={heading3} />
										<span className='s4-title' ref={imgTrigger3}>{t('ourPartners')}</span>
									</div>
									{
										matchesPhone === false
											? (
												<div className='s4-content' ref={heading3}>
													<div className={classes.logoCard}>
														<img className={classes.logoImg} src={partnerImg1} />
													</div>
													<div className={classes.logoCard}>
														<img src={partnerImg2} />
													</div>
													<div className={classes.logoCard}>
														<img className={classes.logoImg} src={partnerImg1} />
													</div>
													<div className={classes.logoCard}>
														<img src={partnerImg2} />
													</div>
												</div>
											)
											: (
												<div className='s4-content' ref={heading3}>
													<div>
														<div className={classes.logoCard}>
															<img className={classes.logoImg} src={partnerImg1} />
														</div>
														<div className={classes.logoCard}>
															<img src={partnerImg2} />
														</div>
													</div>
													<div>
														<div className={classes.logoCard}>
															<img className={classes.logoImg} src={partnerImg1} />
														</div>
														<div className={classes.logoCard}>
															<img src={partnerImg2} />
														</div>
													</div>
												</div>
											)
									}

								</div>

								<div className='section-6'>
									<div className='s4-title-container'>
										<hr className='s4-title-hr' ref={heading3} />
										<span className='s4-title' ref={imgTrigger3}>{t('newsLanding')}</span>
									</div>
									<div className='s4-content' ref={heading3} >
										<LandingArticles />
									</div>

								</div>

							</div>

							<CustomFooter />
						</div>
					</>
			}
		</Translation >
	)
}


const mapStateToProps = ({ clicked, article }) => ({
	// clicked: clicked.clicked
	articles: article.articles
})

const mapDispatchToProps = {
	getLanding
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);