import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
// core components
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet'
// import "leaflet/dist/leaflet.css"
import L, { LatLngExpression } from "leaflet"
import icon from 'assets/img/maps/marker-icon.png';
import icon2x from 'assets/img/maps/marker-icon-2x.png'
import iconShadow from 'assets/img/maps/marker-shadow.png';

// i18n
import { Translation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => createStyles({
  mapRoot: {
    zIndex: 10,
    height: 550,
    width: 550,
    [theme.breakpoints.down('xs')]: {
      width: 320,
      margin: "auto"
    },
  }
}))
interface GoogleMapsProps {
  children?: any;
}

const GoogleMaps: React.FC<GoogleMapsProps> = (props) => {
  const classes = useStyles();

  useEffect(() => {
    const DefaultIcon = L.icon({
      // iconRetinaUrl: icon2x,
      iconUrl: icon,
      shadowUrl: iconShadow
    });

    L.Marker.prototype.options.icon = DefaultIcon;
  }, []);

  const position: LatLngExpression = [21.0357417,105.7806583]


  return (
    <Translation>
      {
        t => <div style={{ height: 'auto', width: '100%' }}>
          <MapContainer className={classes.mapRoot} center={position} zoom={16} scrollWheelZoom={false}>
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>{t('location')}</Popup>
            </Marker>
          </MapContainer>
        </div >
      }
    </Translation>
  )
}



export default GoogleMaps;