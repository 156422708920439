import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { useState } from "react";
import cx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';
import TextInfoContent from '@mui-treasury/components/content/textInfo';
import { useWideCardMediaStyles } from '@mui-treasury/styles/cardMedia/wide';
// import { useN01TextInfoContentStyles } from '@mui-treasury/styles/textInfoContent/n01';
import { useBouncyShadowStyles } from '@mui-treasury/styles/shadow/bouncy';
import { useHistory } from "react-router-dom";
import Tooltip from '@material-ui/core/Tooltip';
// i18n
import { Translation } from "react-i18next"

const useContentN01Styles = makeStyles((theme: Theme) => createStyles({
  overline: {
    textTransform: 'uppercase',
    color: "rgba(0, 0, 0, 0.54)",
    letterSpacing: '1px',
    fontSize: 12,
    marginBottom: '0.875em',
    display: 'inline-block',
    fontFamily: "'Montserrat', sans-serif; !important",
  },
  heading: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: '0.4em',
    fontFamily: "'Montserrat', sans-serif; !important",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "rgb(46,67, 151)"
  },
  body: {
    fontSize: 16,
    color: 'rgba(0,0,0,0.72)',
    fontFamily: "'Montserrat', sans-serif; !important",
    maxHeight: "148px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  }
}))

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: 250,
    fontFamily: "'Montserrat', sans-serif; !important",
    boxShadow: 'none',
    borderRadius: 0,
    [theme.breakpoints.up('sm')]: {
      width: 250,
      marginBottom: 20,
    },
    [theme.breakpoints.up('md')]: {
      width: 250,
      marginBottom: 20,
    },
    [theme.breakpoints.up('lg')]: {
      width: 350,
    },
    [theme.breakpoints.up('xl')]: {
      width: 450,
    },
  },
  content: {
    padding: 24,
    overflow: "hidden",
    height: "290px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  cta: {
    marginTop: 24,
    textTransform: 'initial',
    color: "rgb(46,67, 151)",
    fontFamily: "'Montserrat', sans-serif;",
  },
}))

interface ArticleCard {
  image_url: string;
  title: string;
  slug: string;
  description: string;
  updated_date: string;
  created_date: string;
}

interface NewsCardProps {
  content: ArticleCard;
}

const NewsCard: React.FC<NewsCardProps> = (props) => {
  const classes = useStyles();
  const { content } = props;
  const history = useHistory()
  const mediaStyles = useWideCardMediaStyles();
  const textCardContentStyles = useContentN01Styles();
  const shadowStyles = useBouncyShadowStyles();


  // conver 2021-08-03T03:46:57.872Z to readable date
  const convertInstantToDate = (instant: string): string => {
    const result = ""
    let month = ""

    const firstSplit = instant.split("-")
    const year = firstSplit[0]
    const monthNumber = firstSplit[1]

    const secondSplit = firstSplit[2].split("T")
    const day = secondSplit[0]

    // convert month number to string
    switch (parseInt(monthNumber)) {
      case 1:
        month = "January"
        break;
      case 2:
        month = "February"
        break;
      case 3:
        month = "March"
        break;
      case 4:
        month = "April"
        break;
      case 5:
        month = "May"
        break;
      case 6:
        month = "June"
        break;
      case 7:
        month = "July"
        break;
      case 8:
        month = "August"
        break;
      case 9:
        month = "September"
        break;
      case 10:
        month = "October"
        break;
      case 11:
        month = "November"
        break;
      case 12:
        month = "December"
        break;
      default:
        break;
    }

    return `${month} ${day}, ${year}`
  }

  // redirect to article
  const handleClickArticle = (slug) => {
    history.push(`/articles/${slug}`)
  }

  return (
    <Translation>
      {
        t => <Card className={cx(classes.root, shadowStyles.root)}>
          <CardMedia
            classes={mediaStyles}
            image={content.image_url}
          />
          <CardContent className={classes.content}>
            <div>
              <TextInfoContent
                classes={textCardContentStyles}
                overline={convertInstantToDate(content.updated_date)}
                heading={content.title}
                body={content.description}
              />
            </div>
            <Button
              color={'primary'}
              fullWidth
              className={classes.cta}
              onClick={() => handleClickArticle(content.slug)}
            >
              {t('findOutMore')} <ChevronRightRounded />
            </Button>
          </CardContent>
        </Card>
      }
    </Translation>
  );

}

export default NewsCard;